.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link-element {
  text-decoration: none;
  color: #1B53F9;
}

.error-message {
  color: #ab0505;
  font-size: 15px;
}

.error-toast-message {
  background-color: #ff443826;
  color: #ab0505;
}

.input-border {
  @apply border-[1px] border-[#000];
}

/* .is-error {
  border: 1px solid #ab0505 !important;
  border-radius: 14.5px !important;
} */
.is-error > div {
  border: 1px solid #FF4E43 !important;
  /* border-radius: 14.5px !important; */
}
.is-error fieldset{
  border: none;
}

.custom-style {
  height: 36px !important;
  border-radius: 14.5px !important;
}

.background-gradient{
  background: linear-gradient(42deg, rgba(3,237,165,0.5) 0%, rgba(27,83,249,0.5) 100%);
}

.link-button {
  @apply text-[18px] font-medium p-2 rounded-md no-underline text-[#000] transition delay-100 duration-300 hover:bg-[#000] hover:text-[#fff];
  border: 2px solid #000;
}

.resend-otp {
  @apply text-center
}

.resend-otp > div {
  @apply w-[150px] contents;
}

.resend-otp > div > span {
  @apply my-3;
}

.resend-otp > div > button,
.resend-otp > div > span {
  @apply p-3 cursor-pointer rounded-lg;
  font-size: 18px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* .filterCondition .center-align{
  width: 300px;
  height: 80px;
  display: table;
} */

.filterCondition .filterConditionDiv input[type="radio"]{
  visibility: hidden;
  height: 0;
  width: 0;
}

.filterCondition .filterConditionDiv label {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff00;
  color: #00000099;
  padding: 8px 25px;
  border-radius: 15px;
  border: 1px solid #c3c3c3;
  margin: 0px 6px 0px -6px;
}

.filterCondition .filterConditionDiv input[type="radio"]:checked + label{
  color: #000;
  border: 1px solid #000000;
font-weight: 600;
}
.filterConditionBtn{
  border: 2px solid #c3c3c3;
  padding: 3px 22px;
  border-radius: 15px;
  background-color: #ffffff00;
  font-size: 25px;
  font-weight: 600;
}

.filterConditionSaveBtn{
  border: 2px solid #c3c3c3;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #03eda5;
  font-size: 15px;
  font-weight: 600;
}


.filterConditionCancleBtn{
  border: 2px solid #c3c3c3;
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #dbdbdb;
  font-size: 15px;
  font-weight: 600;
}

.btncssExp {
  /* background-color: #4CAF50;
  border: none;
  color: white;
  padding: 6px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
  margin-right: 5px; */
  font-family: 'Inter' !important;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: 'flex';
  align-items: 'center';
  text-align: right;
  letter-spacing: '-0.154px';
  color: #1B53F9 !important;
}



/* / width / */
::-webkit-scrollbar {
    width: 10px;
  }

  /* / Track / */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* / Handle / */
  ::-webkit-scrollbar-thumb {
    background: #E4E4E5;
    border-radius: 20px;
  }

  /* / Handle on hover / */
  ::-webkit-scrollbar-thumb:hover {
    background: #E4E4E5;
    border-radius: 20px;
  }

/* dashboard setting */
 
.setting_Popover{ 
box-sizing: border-box;

}
.setting_Popover_Search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8d8d8d40;
  padding-bottom: 3px;
  margin-bottom: 10px;
}
.setting_Popover_Search input{
  border: none !important;
  outline: none !important;
}
.setting_Popover input:focus{
  outline: none; 
}

.setting_Popover input::placeholder{
color: #8D8D8D;  
font-size: 12px;
}


.setting_Popover .setting_Popover_btn{
display: flex;
justify-content: center; 
gap: 20px;
position: absolute;
bottom: 25px;

}
.setting_Popover .setting_Popover_btn button{
  width: 150px;
  height: 49px;
  border-radius: 5px;
  background: #DBDBDB;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #040404;
  margin-top: 40px;
  cursor: pointer;
}
.setting_Popover h3{
  color:rgba(0,0,0,0.7);
  font-size: 24px;
  margin: 20px 0px;
}
.css-j204z7-MuiFormControlLabel-root{
  height: 30px;
}
.css-ahj2mt-MuiTypography-root {
  color: #464646;
}

.nodecontainer-scroll {
  height: 270px;
  overflow: auto;
}

.modalEquipmentName{
  font-size: 24px;
  color: #1B53F9;
  margin: 0px;
  font-weight: 600;
}
.modalEquipmentSwitch{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
  color:#2D3748;
      font-weight: 400;
}
.addButton{
  padding: 0px 17px;
    font-size: 26px;
    background-color: white;
    border: 1px solid #444444;
    border-radius: 12px;
    color: #444444;
    margin: 30px 0px;
    cursor: pointer;
}
.EquipmentModalBtns{
  display: flex;
  justify-content: space-between;
}
.EquipmentModalDelBtns{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.EquipmentModalBtns_Apply{
  font-weight: 700;
  border: none;
  background:#03EDA5;
  border-radius: 5px;
  color: #040404;
  padding: 15px 0px;
  width: 260px;
 cursor: pointer;
margin-top: 40px;}
.EquipmentModalBtns_Close{
  font-weight: 700;
  border: none;
  background: #DBDBDB;
  border-radius: 5px;
  color: #040404;
  padding: 15px 0px;
  width: 260px;
cursor: pointer;
margin-top: 40px;}
.modalslider_img{
  display: flex;
  align-items: center;
  gap: 10px
}
.modalslider_img img{
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.modalEquipmentslider{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalEquipmentslider .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  height: 45px;
}
.notification_Content {
  width: 606px;
  height: 500px;
  border-radius: 20px;
  padding: 16px;
}

.notification_Content-header .MuiToggleButtonGroup-root {
  background-color: #F1F1F1;
  height: 38px;
  border: none;
  border-radius: 10px;
  padding: 5px 5px;

}

.notification_Content-header .Mui-selected {
  border: none;
  background: white !important;
  border-radius: 10px !important;
}

.notification_Content-header .MuiButtonBase-root {
  border: none;
  border-radius: 10px;
}

.notification_Content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.notification_Content-header h2 {
  font-weight: 700;
  font-size: 20px;
}

.notification_Content-sub-header {
  border-bottom: 1px solid #0000001A;
  color: #7F7F7F;
  font-size: 16px;
  font-weight: 400px;
}

.notification_Content-sub-header h4 {
  margin: 0;
}

.notification_Content_msg {
  border-radius: 10px;
  background-color: #F5F5F5;
  box-shadow: 0px 0px 5px 0px #B0B0B01A;
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 500px;
  padding: 10px;
  margin-top: 10px
}

.notification_Content_msg p {
  color: #1B53F9;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}