@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap');



/* @font-face {
  font-family: "Inter";
  src: local("Inter-Light"),
    url("./assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Black"),
    url("./assets/fonts/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-ExtraBold"),
    url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}


@font-face {
  font-family: "Inter";
  src: local("Inter-ExtraLight"),
    url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Medium"),
    url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Inter";
  src: local("Inter-Semibold"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Thin"),
    url("./assets/fonts/Inter-Thin.ttf") format("truetype");
} */

* {
  /* font-family: "Medium" !important; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  z-index: 999 !important;
}

.customDropDown .MuiMenuItem-root {
  background-color: #FFF !important;
}

.otp-number>div {
  align-items: center;
  height: 44px;
  border: 1px solid #B8B8B8;
  padding: 28px 0;
  justify-content: center;
  border-radius: 5px
}

.otp-number>div .otp-element input {
  text-align: center;
  width: 20px !important;
  padding: 5px;
  border: 0;
  border-bottom: 1px solid #4A5568;
  font-size: 16px;
}

.otp-number>div .otp-element input:focus-visible {
  outline: none;
}

.form-submit-btn {
  @apply w-full h-[50px] bg-[#03EDA5] rounded-md text-[#040404] text-base font-bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.fltr-btn {
  width: 207px;
  height: 38px;
  /* left: 172px;
  top: 217px; */
  background: rgba(3, 237, 165, 0.05);
  border-radius: 14.5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  color: #1B53F9;
  font-weight: 700;
  margin-left: 10px;
}

/* table css start */
/* .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
} */


.css-1ynyhby {
  margin: 0 !important;
}

.item-1 {
  text-align: left !important;
}

.title-1 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px !important;
}

.grid-2 .item-1 .Typography.css-188gin9-MuiTypography-root:nth-child(2) {
  padding-left: 0px !important;
}

.phase-main-div {
  display: flex;
  align-items: baseline;
  margin-top: 15px !important;
  gap: 10px;
  padding-left: 27px;
}

.subtitle-1 {
  font-size: 14px !important;
  padding-left: 27px;
  text-align: left;
  display: flex;
  align-items: end;
  line-height: 1.3;
}

.phase-div {
  display: flex;
  align-items: baseline;
  font-size: 14px !important;
}

.phase-div span {
  font-size: 10px !important;
}

.pahse-text {
  width: 100%;
  font-size: 8px !important;
  text-align: left;
}

thead tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

thead tr th:last-child {
  border-radius: 0px 10px 10px 0px;
}

/* profile image */
.profile-add-main {
  display: flex;
}

.img-div {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  position: relative;
  margin: 85px auto 0 auto;
}

.img-div>img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;

}

.edit-btn {
  background: #fff;
  width: 61px;
  padding: 2px 5px;
  border: 0.5px solid #000000;
  border-radius: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-btn input#avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  bottom: 0;
}



.wlcm-bck {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 400;
}

.login-main .login-left-form .heading-form {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 30px;
  margin-bottom: 20px;
}

.login-main .login-left-form .MuiFormLabel-colorPrimary,
.login-main .login-left-form .MuiFormControlLabel-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4A5568;
}

.login-main .login-left-form .MuiFormControlLabel-label {
  color: #2D3748 !important;
}






/* dashboard cutout css start*/

/* .active_equipmet_part:after{
  content: "";
  background: whitesmoke;
  position: absolute;
  display: inline-block;
  left: -13px;
  top: -12px;
  right: -12px;
  z-index: 0;
  bottom: 0;
  border-radius: 0 0 0px 0;
  padding: 30px;
} */



/* .MuiBox-root.css-13lvfgh {
  background: #fff;
} */


/* end */

.dashboard-main .MuiTypography-body1 {
  font-family: 'Inter' !important;
  margin-bottom: 0;
}

.dashboard-main * {
  font-family: 'Inter' !important;
}

.dashboard-main-roboto * {
  font-family: 'Roboto' !important;
}

.add-equipment-page input {
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  font-weight: 600;
  color: #2D3748 !important;
  font-family: 'Inter' !important;

}

.add-equipment-page input[type=checkbox] {
  border-radius: 10px !important;
  opacity: 1;
  width: 38px !important;
  height: 38px !important;
  appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  position: relative;

}

.add-equipment-page input:checked:after {
  content: "";
  position: absolute;
  z-index: 1;
  background: url('./assets/checked.png');
  background-repeat: no-repeat;
  top: 5px;
  right: 0;
  left: 6px;
  bottom: 0;
  background-size: 25px;
}

.add-equipment-page .checkbox-inp svg {
  display: none !important;
}

.add-equipment-page .checkbox_main {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.control.checkbox-group {
  display: flex;
  justify-content: space-between;
  margin-left: 10% !important;
}

.add-equipment-page .checkbox-control {
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-equipment-page .checkbox-control label {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  min-width: unset;
}


.search-filter {
  max-width: 80%;
  width: 100%;
  display: flex;
  gap: 20px;
}

.search-filter .search-input {
  width: 62%;
}

.search-filter button {
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  font-family: 'Roboto' !important;
  display: flex;
  align-items: center;
}

.add-export {
  display: flex;
  gap: 15px;
  align-items: center;
}

.search-input input[type="text"] {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 14.5px;
  width: 100%;
  display: block;
  font-size: 14px !important;
  padding: 10px 20px 10px 44px;
  font-family: Roboto !important;
}

.search-input input[type="text"]:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.search-input {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.search-input img {
  position: absolute;
  left: 20px;
  top: 10px;
}

/* filter popup */
.filter-component {
  DISPLAY: flex;
  WIDTH: 100%;
  gap: 15px;
  align-items: center;
}

.filter-component label {
  width: 116px;
}

.filter-component .w-full {
  width: 80%;
}

.filter-component {
  margin-bottom: 30px !important;
}

.filter-modal .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0;
}

.filter-form.MuiBox-root.css-0 {
  padding-top: 30px;
}

.filter-form input {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 14.5px;
}

.filter-modal>div>div {
  max-width: 800px !important;
  width: 100% !important;
  padding: 30px 50px;
  border-radius: 15px;
}

.center-align.filterConditionDiv label {
  padding: 8px 0px;
  font-size: 14px;
  display: grid;
  place-items: center;
  width: 133px;
  margin: 0;
}


.list-table {
  /* z-index: 9; */
  position: relative;
  background: #FFF;
}

.list-table:first-child th,
.list-table:first-child td {
  border-top: none !important;
}

.list-table-open:first-child th,
.list-table-open:first-child td {
  border-top: 1px solid #e0e0e0 !important;
}

.box-1 {
  box-shadow: inset 0px 0px 30px rgb(149 149 149 / 10%);
  margin-top: -15px;
  padding-top: 30px !important;
}

/* 
.list-table th,
.list-table td{
  border-bottom: 0;
}

.list-table-open th,
.list-table-open td{
  border-top: 1px solid #e0e0e0;
}

.list-table-open th{
  border-left: 1px solid #e0e0e0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.list-table-open td:last-child{
  border-right: 1px solid #e0e0e0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
} */



.filter-modal .filter-modal-title {
  padding: 0 !important;
  text-align: center;
  margin-bottom: 20px;
}

.filter-modal>div>div center {
  margin-bottom: 30px;
}

.filter-footer {
  width: 100%;
  max-width: 100%;
}

.filter-footer .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.css-13i4rnv-MuiGrid-root {
  width: 34%;
}

.filter-footer .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-13i4rnv-MuiGrid-root {
  gap: 10px;
}

.filter-footer-btn {
  width: 100%;
}

.filter-option-p {
  position: relative;
}

.center-align.filterConditionDiv {
  justify-content: space-between !important;
  width: 80% !important;
}

.filter-option-p input {
  position: absolute;
  left: 0;
  right: 0;
  height: auto !important;
  margin: 0;
  top: 0;
  width: unset !important;
  bottom: 0;
}

/* toggle drop css */
.action-cell {
  position: relative;
}

.action-main-div {
  background: #FFFFFF;
  /* Stroke Color */
  border: 1px solid #EFF0F6;
  box-shadow: 0px 0px 10px rgba(139, 139, 139, 0.1);
  border-radius: 10px;
  padding: 5px;
  text-align: left;
  position: absolute;
  width: auto;
  /* top: -83px; */
  bottom: 84%;
  left: auto;
  right: 27px;
  z-index: 99 !important;
}

.MuiTableRow-root:first-child .action-main-div {
  bottom: auto;
}

ul.action-main-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.action-main-div li {
  padding: 5px 10px;
  color: rgba(136, 136, 136, 1);
}

.action-main-div li:hover {
  background: rgba(66, 66, 66, 0.05);
  border-radius: 5px;
}

/* chart css start */

/* .tv-lightweight-charts canvas {
  width: 100% !important;
  max-width: 100% !important;
} */

img.chart-bar-icon {
  width: 24px;
}

/* .tv-lightweight-charts {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
} */

/* .tv-lightweight-charts table {
  width: 100% !important;
  max-width: 100% !important;
  margin-right: 0 !important;
}

.tv-lightweight-charts td {
  max-width: 100%;
} */

/* .tv-lightweight-charts div {
  width: 100% !important;
  overflow: unset !important;
  margin: 0 !important;
} */

/* .tv-lightweight-charts tr td:nth-child(3) canvas {
  width: 52px !important;
  max-width: 52px !important;
} */


/* .tv-lightweight-charts tr td:nth-child(2) {
  width: 100% !important;
  max-width: 100% !important;
} */

/* .tv-lightweight-charts tr td:nth-child(3) {
  width: 52px !important;
} */

header .expandAppbar,
.expandSidebar {
  display: none;
}

.ChartAppBar-active {
  margin-left: calc(72px - 0px) !important;
}

header .expandAppbar+.ChartAppBar {
  margin-left: 0 !important;
}

.AppBar,
.ChartAppBar {
  background: #FFFFFF;
  border: 1px solid #EFF0F6;
  box-shadow: 0px 5px 10px rgba(176, 176, 176, 0.1);
}

.sideBarClosed.MuiDrawer-docked .MuiDrawer-paper {
  width: 100px !important;
}

/* dashboard cut css start  */

.total-chart-main-sec .total-pow-opt {
  z-index: 1111;
  position: relative;
  padding: 0 12px;
  padding-left: 35px;
}

.action-cell-icon-active {
  position: relative;
  background: transparent;
  width: unset;
  opacity: 1;
  z-index: 11;
}

.action-cell-icon-active:after {
  content: "";
  background: rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  position: absolute;
  width: 43px;
  height: 43px;
  right: 6px;
  top: 9px;
}

.total-chart-top-sec {
  display: flex !important;
  gap: 10px;
}

.total-chart-title-sec {
  flex: 1 1 60% !important;
}

.active_equipmet_part {
  /* flex: 0 0 auto !important; */
  position: relative;
  z-index: 9;
  max-width: 34%;
  width: 34%;
}

.active_equipmet_part .MuiBox-root.css-1i27l4i {
  background: #fff !important;
  position: relative;
  z-index: 99999 !important;
  padding: 10px 10px;
  /* top: -10px; */
  right: 0;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
}

.recharts-responsive-container {
  z-index: 111;
  position: relative;
}

.total-chart-main-card:after {
  content: "";
  background: #fff;
  width: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 65px;
  height: calc(100% - 51px);
  border-radius: 0 20px 20px 20px;
}

.total-chart-main-card {
  background: unset;
  box-shadow: unset;
  position: relative;
}

.total-chart-title-sec {
  background: #fff !important;
  position: relative;
  z-index: 9 !important;
  padding: 10px;
  /* top: -9px; */
  height: 100%;
  right: 0;
  border-radius: 20px 20px 0 0px;
  padding-left: 50px;
  padding-bottom: 15px;
}

.BoltIcon {
  position: absolute;
  left: 7px;
}

/* end */
.name-cell {
  position: sticky;
  left: 0;
  z-index: 800 !important;
}

.equipment-table-header {
  position: sticky;
  z-index: 999;
  top: 0;
}

/* table stickey highlight */

/* .name-field-cell, .name-cell{
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  background: #fff !important;
    min-width: 230px !important;
    border-radius: unset !important;
}
.eq-name{
  box-shadow:unset !important;
} */

/* end */

.MuiDialog-root {
  z-index: 999 !important;
}

.custom-table-row {
  height: 44px !important;
}

.custom-table-row>th {
  padding: 0 16px;
}

.custom-table table tr td {
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: -0.154px !important;
  color: #4D4D4D !important;
}

/* filter popup css */
.chart-filter-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
  max-width: 985px;
  box-shadow: 0px 0px 5px rgb(176 176 176 / 10%);
  border-radius: 20px;
  padding: 12px;
}

.chart-filter-modal .graph-filter-left {
  padding-right: 10%;
}

.graph-filter-search {
  position: relative;
}

.graph-filter-search .timezone-select label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.graph-filter-search img {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 12px;
}

.graph-filter-search .MuiSelect-select {
  height: 38px !important;
}

.time-range-main {
  margin-bottom: 73px;
}

.filter-footer-btn {
  height: 48px;
}

.filter-footer button.MuiButtonBase-root {
  width: 100% !important;
  height: 48px !important;
  background: #03EDA5;
  border-radius: 5px !important;
  font-family: 'Roboto' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #040404;
}

.filter-footer .filterConditionCancleBtn {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #040404 !important;
}

.time-range-main h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #2D3748;
  margin-bottom: 39px;
}

.date-control-from label {
  min-width: 13%;
}

.date-control-from input {
  max-width: 100%;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 14.5px;
  height: 38px;
  padding: 0 14px 4px 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.date-control-from .input-group {
  position: relative;
  width: 100%;
}

.date-control-from .input-group .calender {
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid #000;
  top: 0;
  padding-left: 7px;
}

.date-control-from input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('./assets/Calendar.png') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

/* .date-control-from input[type="date"]::-webkit-inner-spin-button,
.date-control-from input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; 
     -webkit-appearance: none; 
      opacity: 0;
} */
.date-control-from {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.time-intervals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.time-intervals span {
  flex: 0 0 48%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 14.5px;
  height: 38px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.interval-main {
  margin: 53px 0px;
}

.timezone-select {
  display: flex;
  align-items: center;
}

.timezone-select .MuiSelect-select {
  padding: 0 !important;
}

.timezone-select label {
  min-width: 20%;
}

.timezone-select .w-full {
  width: 100%;
}

.timezone-select .custom-style {
  height: 38px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 14.5px !important;
}

.timezone-select .w-full div {
  width: 100%;
}

/* end */


/* equipment page form css start*/

.eqipment-form .input-control-main {
  display: flex;
  margin-bottom: 26px;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  align-items: baseline;

}

.eqipment-form span.error {
  width: 100%;
  flex: 0 0 100%;
  margin-top: 10px;
  display: flex;
  gap: 15px;
  color: #FF4438;
  font-size: 14px;
}

.eqipment-form label {
  min-width: 165px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #2D3748;
}

.eqipment-form span.error-icon {
  width: 18px !important;
  height: 18px;
  border: 1px solid #FF4438;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eqipment-form .custom-input-control {
  width: 100%;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.eqipment-form .control {
  width: 100%;
  margin-left: 8%;
}

.streetDialog .eqipment-form .eq-form-top {
  margin:  0;
}

.eqipment-form .eq-form-top {
  margin: 50px 0 70px 0;
}

.eqipment-form .eq-form-bottom {
  margin: 60px 0 0 0;
}

.input-group.cordinates-group {
  position: relative;
}

.cordinates-group img {
  position: absolute;
  right: 14px;
  top: 6px;
}

.custom-input-control.input-error {
  border: 1px solid #FF4E43 !important;
}

.footer-button-group {
  justify-content: center !important;
  margin: 80px 0 40px 0 !important;
}

.footer-button-group button {
  height: 48px !important;
}

textarea {
  height: 86px !important;
}

/*  */

/* tree filter view css */
.tree-actions-main {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 50%;
  min-width: 260px;
  justify-content: space-between;
  padding: 0 15px;
  height: 38px;
  align-items: center;
  margin-bottom: 42px;
}

.intetval-active {
  color: black !important;
  background-color: #DBDBDB !important;
}

.tree-section-content {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 2px 2px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  overflow: hidden;
}

.FolderTree {
  height: 1105px;
  overflow-y: auto;
}

.map-view-main .FolderTree {
  background: #fff;
  height: calc(100vh - 235px) !important;


}

.map-toogle {
  transform: translateY(0vw);
  transition: 0.8s ease;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);

}

.map-toogle-active {
  transform: translateY(-100vw);
  transition: 0.8s ease;
}

.map_node_details {
  background: #FFFFFF !important;
  width: 170px;
  height: 165px;
  box-shadow: 5px 5px 10px rgba(106, 105, 105, 0.15);
  border-radius: 13px;
  /* right: 55px; */
  position: absolute;
  /* bottom: 0px; */
  bottom: 50px;

  border: 0.25px solid #04E3AA;
}

.map_node_details-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;

}

.map-view-main div[role=menubar] {
  bottom: 10px !important;
  right: 10% !important;

}

.map-view-main .gm-bundled-control-on-bottom:nth-child(2) {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg) !important;
  right: 100px !important;
  left: unset !important;
  bottom: 12px !important;
}

.gm-control-active {
  transform: translateX(0%) translateY(0%) rotate(-90deg) !important;
}

.map-node-head>.map-node-name {
  color: #1B53F9 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  padding-left: 41px;

}

.map-node-head {
  background: rgba(217, 217, 217, 0.3);
  border-radius: 13px 13px 0px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

/* map */

.property {
  align-items: center;
  /* background-color: #FFFFFF; */
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
}

.property::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  /* border-top: 9px solid #FFFFFF; */
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.property .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
}

.property .icon svg {
  height: 20px;
  width: auto;
}

.property .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.property .address {
  color: #9E9E9E;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.property .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property .features>div {
  align-items: center;
  background: #F5F5F5;
  border-radius: 5px;
  /* border: 1px solid #ccc; */
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/* #copyI {
  z-index: 999999999999999999 !important;
  opacity: 1 !important;
}

.yNHHyP-marker-view{
  z-index: 9999 !important;
} */

/*
 * Property styles in highlighted state.
 */
.property.highlight {
  flex-direction: column-reverse;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  width: 170px;
  height: 165px;
}

.property.highlight::after {
  border-top: 9px solid #FFFFFF;
}

.property.highlight .details {
  display: flex;
}

.property.highlight .icon svg {
  width: 50px;
  height: 50px;
}

.property .bed {
  color: #FFA000;
}

.property .bath {
  color: #03A9F4;
}

.property .size {
  color: #388E3C;
}



/*  */


.tree-section-content .TreeNode {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: relative;
}

.tree-section-content .TreeNode:after {
  content: "";
  width: 100vw;
  bottom: 0;
  position: absolute;
  right: 0;
  margin-left: -50%;
  border-bottom: 1px solid rgba(29, 33, 37, 0.2);
}

.tree-action {
  display: flex;
  gap: 5px;
  align-items: center;
}

span.saprater-icon {
  height: 100%;
}

.saprater-icon img {
  height: 38px;
}

.FolderTree .typeIconContainer {
  display: flex !important;
  align-items: center !important;
  padding: 5% !important
}

.tree-section-header {
  background: #F5F5F5;
  box-shadow: 0px 0px 5px rgb(176 176 176 / 20%);
  border-radius: 10px 10px 0px 0px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  gap: 15px;
}

input:visited {
  border: unset !important;
}

input:focus {
  border: unset !important;
}

.prof-tab-active {
  background: #03EDA50D !important;
}

.tabcus {
  min-height: unset !important;
  height: 32px !important;
  margin-bottom: 24px !important;
  padding: 12px 40px !important;
}

.swal2-close:focus {
  box-shadow: none !important;
}

.swal2-title {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16.94px !important;
  text-align: center !important;
  color: #4D4D4D !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
  margin: 0 30px 0 10px !important;
}

.swal2-popup {
  width: 418px !important;
  height: 76px !important;
  border-radius: 10px !important;
  top: 1em !important;
}

.Toastify__toast {
  height: 76px !important;
  border-radius: 10px;
}

.Toastify__toast-body {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #4D4D4D !important;
}

.Toastify__toast-icon {
  display: none;
}


.MuiTooltip-popper .MuiTooltip-tooltip {
  background: #FFF;
  border: 1px solid #D4D4D4;
  color: #4D4D4D;
  max-width: 130px;
  font-weight: 300;
}

.MuiTooltip-popper .MuiTooltip-tooltip .MuiTooltip-arrow:before {
  border: 1px solid #D4D4D4;
  background-color: #FFF;
}

/* .tab-main>div>div {
  margin-right: 20px !important
}
.tab-main .MuiTabs-indicator{
  background-color: unset !important;

} */


/* module css  */

/* new css tree view custome*/
/* .treeview{
  line-height: 28px;
  font-weight: 500;
  font-size: 15px;
}
.treeview,
.treeview ul,
.treeview ul li{
  width: 100%;
  float: left;
  border-top: 1px solid rgba(29, 33, 37, 0.2);
  font-family: 'Inter' !important;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #4D4D4D;
padding: 16px 0;

}
.treeview .folder{
  position: relative;
}
.treeview .folder i{
  padding-left: 22px;
}
.treeview .folder:before,
.treeview .file:before,
.treeview .external:before{
  font-family:'FontAwesome';
  float: left;
  margin-right: 6px;
}
.treeview .folder:before{
  content: "\f114";
  position: absolute;
  left: 17px;
  font-size: 17px;
}
.treeview .file:before{
  font-size: 90%;
  content: "\f016"
}
.treeview .external:before{
  font-size: 90%;
  content: "\f122";
}
.treeview .extend .folder > ul{
  display: none;
  margin-left: 18px;
}
.treeview .extend label{
  float: left;
}
.treeview .extend input{
  display: none;
}
.treeview .extend input[type=checkbox] + i:before {
  content: "\f196";
  color: #111;
  float: left;
  font-size: 14px;
  margin-left: 1px;
  margin-top: 7px;
  cursor: pointer;
}
.treeview .extend input[type=checkbox]:checked + i:before{
  content: "\f147";
}
.treeview .extend input[type=checkbox]:checked ~ ul{
  display: block;
  padding: 0;
} */
/* custome end */

.equipment-pagination .MuiNativeSelect-select {
  border: 1px solid #B3B3B3;
  border-radius: 3px;
  padding: 0 30px 0 8px !important;
  font-size: 14px;
  color: #1B53F9;
  background: rgba(3, 237, 165, 0.05);
}

.equipment-pagination .MuiTablePagination-selectLabel {
  font-size: 12px;
}

/*  */
@media(max-width:1600px) {
  .phase-div {
    font-size: 10px !important;
  }

  .total-chart-top-sec {
    grid-template-columns: repeat(1, minmax(100%, 1fr)) !important;
    display: flex;
  }

  .total-chart-top-sec {
    display: flex !important;
    justify-content: space-between;
    /* margin-top: 10px; */
  }

  .active_equipmet_part {
    max-width: 50%;
    width: 50%;
  }


}

.datetime-picker .rdtPicker {
  width: 100% !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px !important;
}

input[disabled] {
  background-color: #D3D3D3 !important;
}

.geo_co_ordinates_lat_field {
  width: 40% !important;
}

.geo_co_ordinates_lng_field {
  width: 40% !important;
  margin-left: 1rem;
}

.geo_co_ordinates_btn {
  position: initial !important;
  margin-left: 1rem;
  width: 40px !important;
  height: 38px !important;
}

.MuiMenu-list {
  padding-top: 0;
  padding-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.breadcrumb-item-arrow {
  display: flex;
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
}

.move-btn-dialog:hover {
  background: #03EDA5 !important;
  color: #040404 !important;
}

.node-path-active {
  background: rgba(3, 237, 165, 0.2)
}

.node-path-item:hover {
  background: rgba(3, 237, 165, 0.05)
}

/* Menu */
.action-menu {
  border: 1px solid #EFF0F6;
  box-shadow: 0px 0px 10px rgba(139, 139, 139, 0.1);
  border-radius: 10px;
  right: 27px;
}

.CaretRightIcon {
  color: #7F7F7F;
}

.CaretDownIcon {
  color: #7F7F7F;
}

.editableNameContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.row-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.remove-equipment-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  text-align: center;
}

.remove-equipment-confirm-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.filter-footer button.MuiButtonBase-root .Mui-disabled {
  background-color: #dbdbdb !important;
  color: #FFFFFF !important;
}

.current_group_input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiAutocomplete-tag {
  background: rgba(3, 237, 165, 0.5) !important;
  color: #2D3748 !important;
}

.file-container {
  width: 560px;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  height: 114px;
  display: flex;
  justify-content: center;
}

.dropzone {
  display: flex;
  align-items: center;
}

.dropzone button {
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.profile-menu .MuiMenuItem-root {
  padding: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
}

.validate-file {
  font-size: 11px;
  color: red;
  line-height: 0.5;
}

.file-preview-inner {
  display: flex;
  align-items: center;
}

.auto-refresh-button .MuiBadge-badge {
  background-color: #02F5A0;
  top: 5px;
  right: 5px;
}
.map_Nodes{
  z-index: 999;
  position: absolute;
  top: 85px;
  right: 20px;
  width: 250px;
  background: white;
  border-radius: 13px;
  overflow: hidden;
}
.map_Nodes_NoData{
  text-align: center;
  font-size: 18px;
  font-weight:  600px;
}
.map_Nodes-title{
  background: rgba(217, 217, 217, 0.3);
  padding:15px;
}
.map_Nodes-title-active h4{
  font-size: 12px !important;
  font-weight: 500;
  color: #4D4D4D;
  margin: 0;
  padding-top: 5px;
}
.map_Nodes-title-active h5{
  font-size: 16px ;
  font-weight: 600;
  margin: 0;
}
.map_Nodes-title-active{
  display:flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.map_Nodes-title-active span{
  font-size: 18px;
  font-weight: 600;
}
.map_Nodes-title-nodeName{
  display: flex;
  justify-content:space-between;
  align-items:center;
}
.map_Nodes-title-nodeName span{
  padding: 5px;
  width: 20%;
  text-align:right;
  cursor: pointer;
}
.map_Nodes-title p{
  color: #1B53F9;
  font-size:14px;
  margin:0;
}
.map_Nodes-content{
padding: 20px 15px;
}
.map_Nodes-content h4{
  font-style:normal;
  font-weight:600;
  font-size: 16px;
  margin: 0;
  }
.map_Nodes-content p{
  font-size: 10px;
  color: #8D8D8D;
  font-weight: 400;
  font-family: 'Roboto';
}

.map_Nodes-content_value{
  display: flex;
  justify-content: space-between;
  align-items:center
}
.map_Nodes-content_value div{
  display: flex;
  gap: 5px;
}
.map_Nodes-content_value img{
  width: 20px
}
.map_Nodes-title-active_Active{
  color: green;
}
.map_Nodes-title-active_InActive{
  color: red;
}
.map_Nodes-title-active_Total{
  color: #4D4D4D;
}
.map_Nodes_StreetLight h4{
  margin-top: 15px;
  color: #8d8686;
  font-size: 13px;
} 
.equipment_setting_tabs-container{
  height:100vh;
  display:flex;
  width:100%;
  box-sizing: border-box;
  overflow: hidden;
}
.equipment_setting_container{
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
  padding: 0 40px;
  padding: 100px 40px 40px 40px;
}
.equipment_setting_header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #1D2125;
}
.equipment_setting_header h3{
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color:#2D3748;
  margin: 0;
}
.equipment_setting_header h4{
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color:  #2D3748;
margin: 10px 0 7px 0;
}
.equipment_Setting_selecter{
  height:44px;
  border-radius: 10px;
  background-color: #F5F5F5;
  margin-top: 25px;
  display: flex;
  justify-content:space-between;
  align-items:center;
}
.equipment_Setting_selecter_select{
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #7F7F7F !important;
}
.equipment_setting-add_btn{
 margin:100px 0;
 text-align: center;
}
.equipment_setting-add_btn button{
  font-weight: 500;
  font-size: 19px;
  border: none;
  background: #03EDA5;
  border-radius: 10px;
  color: white;
  width: 220px;
  cursor: pointer;
  height: 49px;
}
.equipment_setting-alert_message-header h4{
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #2D3748;
  margin: 10px 0 7px 0;
}  
.equipment_setting-alert_message-header{
  border-bottom: 1px solid #1D2125;
}
.equipment_setting-alert_message-checkBox{
  display: flex;
  gap: 50px;
}  
.equipment_setting-alert_message-checkBox div{
  display: flex;
  align-items: center;
  gap: 10px;
}
.equipment_setting-alert_message-checkBox input{
  width: 18px;
  height: 18px;
  border-radius: 2px;
}
.equipment_setting-alert_message-input{
  display: flex;
  align-items: center;
  gap: 50px;
  margin: 20px 50px 20px 0;
}
.equipment_setting-alert_message-email{
  align-items: flex-start;
}
.equipment_setting-alert_message-email p{
  margin-top:0
}
.equipment_setting-alert_message-allEmail{
  display: flex;
  justify-content: center;
}
.equipment_setting-alert_message-allEmail ul{
  margin:0
}
.equipment_setting-alert_message-input p{
  /* width: 125px; */
  font-size: 16px;
  font-weight: 400;
  color: #2D3748;
  width: 30%;
  /* margin: 0 50px 0 0; */
}
.equipment_setting-alert_message-input h5{
  margin: 5px 0px 0px 5px;
  color: red;
  font-size: 12px;
  font-weight: 500;
}
.equipment_setting-alert_message-input_text{
  width:70%
}
.email_box{
  border: 1px solid #888888;
  padding: 0px 10px;
  border-radius: 10px;
}
.email_box-input{
  width: auto !important;
  height: auto !important;
  border: none !important;
  outline: none;
  padding: 10px !important;
}
.equipment_setting-alert_message-input_text input{
  width: 100%;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #888888;
  padding: 0px 0px 0px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
}
.equipment_setting-alert_message_buttons{
  display: flex;
  justify-content: end;
  gap: 30px;
  margin-right: 50px;
}
.equipment_setting-alert_message_buttons-btn{
  height: 46px;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #DBDBDB;
}
.set_alert_btn-selected{
  height: 46px;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #04E3AA;
}

.equipmentSetting-table-header{
  z-index: 2 !important;
  margin-top: 25px;
}
.enter_value{
  width: 111px;
  height: 30px;
  border-radius: 10px;
  border: 0.5px solid #4D4D4D;
  font-size: 12px;
  font-weight: 400;
  color: #8D8D8D;
  padding: 0 10px;
}
.enter_from_to{
  display: flex;
  align-items: center;
}
.enter_from_to label{
  margin-right: 5px;
  font-size: 12px;
  font-weight: 300;
  color: #4D4D4D;
}
.enter_from_to input{
  /* width: 59px;
  height: 30px;
  border-radius: 10px;
  border: 0.5px solid #7F7F7F;
  margin-right: 10px; */
  font-size: 12px;
  font-weight: 400;
  color: #8D8D8D;
  padding: 5px;
  /* padding: 0 10px; */
}
.enter_from_to svg{
  width: 18px
}
.enter_from_to button{
  padding:0px;
}
.alert_limit{
  display: flex;
  align-items: center;
}
.alert_limit input{
  width: 59px;
  height: 30px;
  border-radius: 10px;
  border: 0.5px solid #7F7F7F;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #8D8D8D;
  padding: 0 10px;
}
.alert_limit label{
  font-size: 12px;
  font-weight: 300;
  color: #4D4D4D;
}
.error_checkbox  {
width: 18px;
height: 18px;
border: 2px solid red;}
.equipment_setting_select{
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #8D8D8D !important;
}
.equipment_setting-add_btn-plus{
  margin: 20px 0 50px 0;
  text-align: center;
}
.equipment_setting-add_btn-plus button{
  border-radius: 14.5px;
  border: 1px solid #444444;
  font-weight: 500;
  font-size: 30px;
  color: #444444;
  background-color: white;
  cursor: pointer;
  line-height: 12px;
  padding: 15px 20px 14px 20px;
}

.equipment_setting_tabs-container .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular { 
  align-items: baseline;
}
.equipment_settings_tableCell{
  font-size:14px;
  font-weight: 500;
  color: #4D4D4D;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
.first_tableCell{
  padding-left: 15px;
}
.equipment_settings_tableCell span{
  font-size:12px;
  font-weight: 300;
  color:#4D4D4D;
  margin-right:5px;
}

.equipment_settings_tableCell-alertLimit{
  display: flex;
  align-items: center;
  gap: 3px;
}
.equipment_settings_tableCell-alertLimit p{
  margin:0;
  font-size:14px;
  font-weight: 500;
  color: #4D4D4D;
}
.equipment_settings_tableCell-Checkbox{
  width: 14px;
  height: 14px;
  border: 1px solid #0000004D;
  border-radius: 2px;
}
.alert-dialog-description{
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #2D3748 !important;
  /* padding: 40px 80px !important; */
}
.alert-dialog-description span{
color: #1B53F9 !important;
}
.alert-dialog-title-description{
  padding: 40px 80px !important;
}
.checkBox_Cell{
  background: rgb(245, 245, 245);
  border-bottom: 0 !important;
}
.equipment_settings_tableCell-deleteIcon{
  width: 17px !important;
  height: 17px !important;
  cursor: pointer;
}
.empty_alert_data{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.equipment_setting_select-error{
  color: red;
  font-size: 12px;
  font-weight:500;
  margin:5px 0px 0px 5px;

}
.input_error {
  border: 1px solid #d32f2f  !important;
}
.input_error:focus {
  outline: 2px solid #d32f2f !important;
}